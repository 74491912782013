// menu-overlay
.menu-overlay {
	@include media-breakpoint-up(xl) {
		z-index: -1;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
	}
	background: $primary;
	&.collapse:not(.show) {
		display: none;
	}
	&::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		width: 100%;
		height: 55px;
		margin-top: -1px; /* pixel fix */
		background: url("/images/edge-blue-bottom.svg") repeat-x center top;
	}

	// menu
	.menu {
		display: flex;
		@include media-breakpoint-down(lg) {
			margin-bottom: 15px;
			.navbar-nav {
				width: 100%;
				> .nav-item {
					> .nav-link {
						color: $white;
						font-size: 16px;
						font-weight: 400;
						border-bottom: 1px solid rgba($white, 0.5);
						&:hover {
							color: unset;
						}
					}
				}
				.nav-item {
					.nav-link {
						color: $white;
						&:hover {
							color: $secondary;
						}
						&.dropdown-toggle {
							&::after {
								content: "\f107";
								position: absolute;
								top: 0;
								right: 0;
								color: $white;
								font-size: 18px;
								line-height: 40px;
								font-family: $font-awesome;
								border: none;
								margin-left: 0;
							}
						}
					}
					&.active {
						.nav-link {
							color: $secondary;
						}
					}
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 20px;
						padding: 0;
						border: none;
						background-color: transparent;
					}
				}
			}
		}
		@include media-breakpoint-up(xl) {
			margin: 180px 0 6vh 0;
			.navbar-nav {
				width: 100%;
				flex-wrap: wrap;
				flex-direction: row;
				.nav-item {
					.nav-link {
						padding: 0;
						color: rgba($white, 0.70);
						&:hover {
							color: $white;
						}
					}
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						display: block;
						margin: 5px 0 0 0;
						padding: 0;
						border: none;
						background-color: transparent;
					}
				}
				> .nav-item {
					margin-bottom: 45px;
					padding-right: 30px;
					@include make-col(3);
					> .nav-link {
						color: $white;
						font-size: 20px;
						font-family: $font-family-secondary;
						pointer-events: none;
						&::after {
							display: none;
						}
						&:hover {
							color: unset;
						}
					}
				}
			}
		}
	}
}
