.bg-dark {
	&.bg-purple {
		background: $purple !important;
		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			width: 100%;
			height: 55px;
			margin-top: -1px; /* pixel fix */
			background: url("/images/edge-purple-bottom.svg") repeat-x center top !important;
		}
	}

	a:not(.btn) {
		color: $body-color--dark;
		&:hover {
			color: darken($body-color--dark, 15%);
		}
	}

	// btn
	.btn {
		/* btn-outline-primary */
		&.btn-outline-primary {
			border-color: $white;
			color: $white;
			&:hover {
			}
		}

		/* btn-outline-secondary */
		&.btn-outline-secondary {
			border-color: $white;
			color: $white;
			&:hover {
				background: $secondary;
			}
		}
	}
}

// text-light
.text-light {
	color: $body-color--dark;
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $body-color--dark;;
	}
	a:not(.btn) {
		color: $body-color--dark;
		&:hover {
			color: darken($body-color--dark, 15%);
		}
	}
}
