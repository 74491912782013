// bg-dark
// =========================================================================
section {
	&.bg-dark {
		position: relative;
		margin-top: 0;
		margin-bottom: calc(6vh + 55px);
		padding: 6vh 0;
		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			width: 100%;
			height: 55px;
			margin-top: -1px; /* pixel fix */
			background: url("/images/edge-green-bottom.svg") repeat-x center top;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	position: relative;
	margin: 6vh 0;
	color: $tertiary;
	&:not(.bg-dark) {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $tertiary;
		}
	}
	p:last-of-type {
		margin-bottom: 0;
	}

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}
	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;
			li {
				margin: 0 15px;
			}
			.icon {
				margin-right: 5px;
				color: $quaternary;
			}
			.label {
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	position: relative;
	margin: 6vh 0;

	/* intro/outro */
	.intro,
	.outro {
		max-width: 750px;
		.container-holder {
			text-align: center;
		}
	}
	.intro {
		.container-holder {
			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.outro {
		.container-holder {
			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	/* narrow/wide */
	.column.narrow {
		@include media-breakpoint-up(lg) {
			@include make-col(5);
		}
	}
	.column.wide {
		@include media-breakpoint-up(lg) {
			@include make-col(7);
		}
	}

	/* featured-box */
	.featured-box {
		margin: 3vh 0;
		.wysiwyg {
			padding: 30px;
			@include media-breakpoint-up(xl) {
				padding: 50px 15%;
			}
			background-color: $quaternary;
			border-radius: 5px;
			text-align: center;
			@extend .text-light;
			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// photos-section
// =========================================================================
.photos-section {
	position: relative;
	margin: 6vh 0;
	&.bg-purple-half {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 50%;
			background: $purple;
		}
		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			width: 100%;
			height: 55px;
			margin-top: -1px; /* pixel fix */
			background: url("/images/edge-purple-bottom.svg") repeat-x center top;
		}
	}
	.container-two-columns {
		.container-holder {
			> .column.one {
				@extend .pr-md-3;
				@extend .pr-lg-3;
			}
			> .column.two {
				@extend .pl-3;
				@extend .pl-md-3;
				@extend .pl-lg-3;
			}
		}
	}
	.resource-image {
		img {
			border-radius: 5px;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	&.bg-half-green {
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 35%;
			background: $green;
		}
	}
	&.bg-half-purple {
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 35%;
			background: $purple;
		}
	}
	.owl-carousel {
		height: 70vh;
		min-height: 300px;
		max-height: 660px;
		.owl-stage-outer {
			border-radius: 5px;
			overflow: hidden;
		}
		.item {
			align-items: flex-end;
			height: 70vh !important; /* overide */
			min-height: 300px;
			max-height: 660px;
			padding: unset; /* overide */
			.owl-container {
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
				.owl-caption {
					display: flex;
					flex-direction: column;
					padding: 30px 15px;
					@include media-breakpoint-up(xl) {
						padding: 30px 35px;
					}
					color: $white;
					text-align: left;
					.owl-title {
						order: 1;
						margin-bottom: 0;
						font-size: 24px;
						font-weight: 400;
						line-height: 1.2;
						text-shadow: none;
						font-family: $font-family-secondary;
						@include media-breakpoint-up(xl) {
							font-size: 36px;
						}
					}
					.owl-subtitle {
						order: 0;
						font-size: 13px;
						font-weight: 400;
						text-transform: uppercase;
						font-family: $font-family-primary;
						text-shadow: none;
					}
					.owl-description {
						order: 2;
						font-size: 13px;
						text-shadow: none;
					}
					.owl-btn {
						order: 3;
					}
				}
			}
		}
		.owl-dots,
		.owl-nav {
			@extend .d-none;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// bundle (section overrides)
// =========================================================================
&.bundle-category,
&.bundle-detail {
	.lead-section {
		.container-one-column {
			.container-holder {
				max-width: none;
				margin: 0 -15px;
				.info {
					max-width: 750px;
					margin: 0 auto;
					text-align: center;
				}
			}
		}
	}
}

// accommodation
&.accommodation-book,
&.accommodation-search-book,
&.accommodation-detail {
	.lead-section {
		margin-top: calc(6vh + 55px);
	}
}

// accommodation-detail
&.accommodation-detail {
	.eyecatcher {
		display: none;
	}
	.gallery-section {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 75%;
			background: $purple;
		}
	}
	.content-section {
		@extend .bg-dark;
		@extend .bg-purple;
	}
}
// service-detail
&.service-detail {
	.gallery-section {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: 25%;
			left: 0;
			right: 0;
			height: 65%;
			background: $green;
		}
		&::after {
			content: "";
			z-index: -1;
			position: absolute;
			top: 90%;
			left: 0;
			right: 0;
			width: 100%;
			height: 55px;
			margin-top: -1px; /* pixel fix */
			background: url("/images/edge-green-bottom.svg") repeat-x center top;
		}
		.gallery-horizontal {
			.gallery-item {
				margin-bottom: 20px;
				@include media-breakpoint-up(xl) {
					flex: 0 0 20%;
					max-width: 20%;
				}
				&.large-item {
					@include media-breakpoint-up(lg) {
						@include make-col(12);
					}
				}
			}
			.gallery-thumbs {
				@include media-breakpoint-up(lg) {
					@include make-col(12);
				}
				justify-content: center;
			}
		}
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;
	h2 {
		@extend .h1;
		margin-bottom: 30px;
		text-align: center;
	}
}
