.collection {
	// grid
	&.grid {
		.grid-items {
			.item {
				@include media-breakpoint-up(xl) {
					@include make-col(4);
				}
			}
		}
	}
}
