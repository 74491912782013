// default
.card {
	background: $white;
	border: none;
	border-radius: 0;
	box-shadow: 3px 3px 12px rgba($black, 0.15);
	.card-image {
		overflow: hidden;
		.card-image-link {
			@extend .d-block;
			.card-img {
				transition: 0.5s ease-in-out;
			}
			&:hover {
				.card-img {
					transform: scale(1.03);
				}
			}
		}
	}
	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 20px;
		}
		.card-caption {
			display: flex;
			flex-direction: column;
			.card-subtitle {
				opacity: 0.6;
				font-size: 13px;
				font-style: normal;
				text-transform: uppercase;
				font-family: $font-family-primary;
			}
			.card-title,
			.card-title-link {
				order: 1;
			}
			.card-description {
				order: 2;
				.card-description-content {
					line-height: 1.25;
				}
				.list {
					margin-top: 15px;
					.icon {
						color: $quaternary;
					}
				}
			}
		}
		.card-buttons {
			.card-btn {
				@extend .btn-link;
			}
		}
	}

}
