.header {
	z-index: 999;
	position: relative;
	@include media-breakpoint-up(xl) {
		position: sticky;
	}
	top: 0;
	left: 0;
	right: 0;
	background: $primary;
	&::after {
		content: "";
		z-index: -2;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		width: 100%;
		height: 55px;
		margin-top: -1px; /* pixel fix */
		background: url("/images/edge-blue-bottom.svg") repeat-x center top;
	}

	// header-main
	.header-main {
		padding: 15px 0;
		transition: $transition-base;
		@include media-breakpoint-up(xl) {
			padding: 25px 15px;
		}
		.column.default {
			align-items: center;
			@include media-breakpoint-down(lg) {
				flex-flow: row wrap;
				justify-content: center;
			}
			@extend .navbar-expand-xl;
		}

		// logo
		.logo {
			max-width: 235px;
			margin: 0 auto;
			transition: 0.5s;
			@include media-breakpoint-down(sm) {
				order: 4;
				margin-top: 20px;
				width: 100%;
				max-width: unset;
				a {
					max-width: 235px;
					margin: 0 auto;
				}
			}
			@include media-breakpoint-up(md) {
				z-index: 100;
				position: absolute;
				bottom: -110px;
				left: 0;
				right: 0;
			}
			@include media-breakpoint-up(lg) {
				top: -5px;
				left: 110px;
				right: auto;
			}
			a {
				@extend .d-block;
			}
		}

		// menu-toggle
		.menu-toggle {
			margin-right: auto;
			.navbar-toggler {
				display: flex !important;
				align-items: center;
				justify-content: center;
				width: 33px;
				height: 33px;
				margin-left: -5px;
				padding: 0;
				border: 1px solid transparent;
				border-radius: 2px;
				color: $white;
				font-size: 24px;
				transition: 0.5s;
				text-align: center;
				line-height: 38px;
				&[aria-expanded="true"] {
					i {
						&::before {
							content: "\f00d";
						}
					}
				}
				&:hover {
					border-color: $white;
					background: $white;
					color: $quaternary;
				}
			}
		}

		// header-button
		.header-button {
			ul {
				margin-bottom: 0;
				li {
					margin-right: 0;
					a {
						@extend .btn;
						@extend .btn-outline-white;
					}
				}
			}
		}

		// site-switcher
		.site-switcher {
			margin-left: 15px;
			.language {
				flex-direction: row;
				margin-bottom: 0;
				.nav-item {
					+ li {
						margin-left: 5px;
					}
					.nav-link {
						display: block;
						padding: 0;
						border-radius: 100%;
						img {
							display: block;
							width: 21px;
							height: 21px;
							opacity: 0.75;
						}
					}
					&:hover:not(.active) {
						.nav-link {
							box-shadow: 0 0 10px rgba($white, 0.75);
						}
					}
					&.active {
						.nav-link {
							img {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

	&.sticky {
		@include media-breakpoint-up(xl) {
			position: sticky;
		}
		.header-main {
			@include media-breakpoint-up(xl) {
				padding: 15px 15px 0 15px;
			}
			.logo {
				@include media-breakpoint-up(xl) {
					max-width: 170px;
					top: 0;
				}
			}
		}
	}
}

&.menu-overlay-active {
	.header-main {
		.logo {
			@include media-breakpoint-down(lg) {
				margin: 0;
				height: 0;
				overflow: hidden;
			}
			@include media-breakpoint-up(xl) {
				max-width: 170px;
			}
		}
	}
}
