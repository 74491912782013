// btn
.btn {
	padding: 5px 15px;
	@include media-breakpoint-up(xxl) {
		padding-left: 25px;
		padding-right: 25px;
	}
	border-radius: 2px;
	font-size: 14px;
	font-weight: 500;

	/* btn-primary */
	&.btn-primary {
		&:hover {
			border-color: $secondary;
			background: $secondary;
		}
	}

	/* btn-outline-primary */
	&.btn-outline-primary {
		&:hover {
		}
	}

	/* btn-secondary */
	&.btn-secondary {
		&:hover {
			border-color: $primary;
			background: $primary;
		}
	}

	/* btn-outline-secondary */
	&.btn-outline-secondary {
		&:hover {
		}
	}

	/* btn-white */
	&.btn-white {
		border-color: $white;
		background: $white;
		color: $quaternary;
		&:hover {
			border-color: $white;
			background: transparent;
			color: $white;
		}
	}

	/* btn-outline-white */
	&.btn-outline-white {
		border-color: $white;
		background: transparent;
		color: $white;
		&:hover {
			background: $white;
			color: $quaternary;
		}
	}

	/* btn-link */
	&.btn-link {
		position: relative;
		padding: 0;
		border-radius: 0;
		border: none;
		background: transparent;
		color: $primary;
		font-size: 16px;
		text-decoration: none;
		border-bottom: 1px solid $primary;
		&::after {
			content: "\f178";
			font-family: "Font Awesome 5 Pro";
			position: absolute;
			margin-left: 8px;
			color: $quaternary;
			font-weight: 900;
			line-height: 26px;
			transition: 0.5s;
		}
		&:hover {
			color: $quaternary;
			text-decoration: none;
			border-bottom-color: $quaternary;
			&::after {
				margin-left: 10px;
			}
		}
	}
}

// btn-back
.btn-back {
	position: relative;
	display: block;
	margin: 0 0 0 25px !important;
	color: $primary;
	font-size: 16px;
	text-decoration: none;
	border-bottom: 1px solid $primary;
	&::before {
		content: "\f177";
		font-family: "Font Awesome 5 Pro";
		position: absolute;
		left: -25px;
		margin-right: 0;
		color: $quaternary;
		font-weight: 900;
		transition: 0.5s;
	}
	&:hover {
		color: $quaternary;
		text-decoration: none;
		border-bottom-color: $quaternary;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-secondary;
	&::after {
		content: "\f175";
		font-family: "Font Awesome 5 Pro";
		margin-left: 10px;
		font-weight: 900;
	}
}
