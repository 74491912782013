// eyecatcher
.eyecatcher {
	background: $secondary;
	min-height: unset; /* overide */
	.owl-carousel {
		height: 50vh;
		min-height: 300px;
		max-height: 540px;
		.item {
			height: 50vh !important; /* overide */
			min-height: 300px;
			max-height: 540px;
			padding: unset; /* overide */
			.owl-caption {
				margin-bottom: 65px;
			}
		}
	}
	&.large {
		min-height: unset; /* overide */
		.owl-carousel {
			height: calc(90vh - 120px);
			min-height: 480px;
			max-height: 1080px;
			.item {
				height: calc(90vh - 120px) !important; /* overide */
				min-height: 480px;
				max-height: 1080px;
				padding: unset; /* overide */
				.owl-caption {
					margin-bottom: 85px;
				}
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		.item {
			align-items: flex-end;
			&::before {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 250px;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
				opacity: 0.65;
			}
			.owl-caption {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				position: relative;
				max-width: 1110px;
				margin-left: auto;
				margin-right: auto;
				@include media-breakpoint-up(md) {
					align-items: center;
					text-align: center;
				}
				color: $white;
				text-align: unset;
				.owl-title {
					order: 1;
					margin-bottom: 0;
					font-size: 36px;
					font-weight: 400;
					line-height: 1.2;
					text-shadow: none;
					font-family: $font-family-secondary;
					@include media-breakpoint-up(xl) {
						font-size: 60px;
					}
				}
				.owl-subtitle {
					order: 0;
					margin-bottom: 0;
					font-size: 13px;
					font-weight: 400;
					text-transform: uppercase;
					font-family: $font-family-primary;
					@include media-breakpoint-up(xl) {
						font-size: 18px;
					}
					text-shadow: none;
				}
				.owl-description {
					order: 2;
					text-shadow: none;
				}
				.owl-btn {
					order: 3;
				}
			}
		}
		.owl-dots,
		.owl-nav {
			@extend .d-none;
		}
	}
}
