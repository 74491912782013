.mini-sab {
    .container {
		position: relative;
		@extend .d-flex;
        @extend .justify-content-center;
		.container-holder {
			z-index: 100;
			position: relative;
			min-height: 80px;
			margin: -45px 0 0 0;
			padding: 10px 5px 20px 5px;
			border-radius: 5px;
			background: $secondary;
			color: $white;
		}
	}
}
