// headings
h1 {
	@include media-breakpoint-down(lg) {
		font-size: $h1-font-size-mobile;
	}
}
&.home h2 {
	font-size: $h1-font-size;
	@include media-breakpoint-down(lg) {
		font-size: $h1-font-size-mobile;
	}
}
h4 {
	color: $quaternary;
	font-style: italic;
}
h5 {
	margin-bottom: 0;
	opacity: 0.6;
}
h6 {
	color: $quaternary;
}
h5,
h6 {
	font-weight: 400;
	text-transform: uppercase;
	font-family: $font-family-primary;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}
iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}
