// colors
$cream: #f8f6f5;
$blue: #02233f;
$brown: #a98f5e;
$green: #474734;
$red: #ad313c;
$purple: #7b5e7b;

// theme-colors
$primary: $blue;
$secondary: $brown;
$tertiary: $green;
$quaternary: $red;
$quinary: $purple;

$dark: $green;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;
$matrix-color-tertiary: $tertiary;
$matrix-color-quaternary: $quaternary;

// body
$body-bg: $cream;
$body-color: $blue;

// links
$link-color: $quaternary;
$link-hover-color: $primary;

// typography
@import url('https://fonts.googleapis.com/css2?family=Lora:ital@0;1&family=Outfit:wght@400;500;700&display=swap');
$font-family-primary: 'Outfit', sans-serif;
$font-family-secondary: 'Lora', serif;
$font-awesome: 'Font Awesome 5 Pro';
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.25; // 36px
$h2-font-size: $font-size-base * 1.875; // 30px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.125; // 18px
$h5-font-size: $font-size-base * 0.8125; // 13px
$h6-font-size: $font-size-base * 0.6875; // 11px

$h1-font-size-mobile: $font-size-base * 1.5; // 24px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;
