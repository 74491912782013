// footer
.footer {
	margin-top: 6vh;

	// footer-partners
	.footer-partners {
		margin-bottom: calc(2vh + 55px);
		ul {
			@extend .list-unstyled;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
			li + li {
				margin-left: 30px;
			}
		}
	}

	// footer-address
	.footer-address {
		@extend .text-light;
		position: relative;
		margin-top: 55px !important;
		padding: 8vh 0 !important;
		background: $primary !important;
		text-align: center;
		&::before {
			content: "";
			z-index: -1;
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			height: 55px;
			margin-bottom: -1px;
			background: url("/images/edge-blue-top.svg") repeat-x center bottom;
		}
		.logo {
			margin: 0 auto 30px auto;
			a {
				@extend .d-block;
			}
		}
		.footer-text {
			p {
				margin-bottom: 30px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			a {
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	// footer-socket
	.footer-socket {
		padding: 20px 0;

		// footer-link
		.footer-link {
			.list {
				text-align: center;
				justify-content: center;
				.list-item {
					margin: 10px;
					font-size: 13px;
					.link {
						color: $primary;
						text-decoration: none;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
